import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import {
  Button,
  Checkbox,
  Dimmer,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import 'react-table/react-table.css';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';

import { ListDefenders } from '../../defender/DefenderApi';
import { getUserAttributes } from '../../../services/Authentication';

class SearchDefendersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      size: 'fullscreen',
      loading: false,
      defenderArray: [],
      defenderResponse: [],
      defenderSelectAll: false,
      defenderSelection: [],
      defenderDeselection: [],
      jurisdiction: '',
    };
  }

  async componentWillMount() {
    const attributes = await getUserAttributes();
    this.setState({ jurisdiction: attributes.jurisdiction });
  }

  componentDidMount() {
    const { defenders } = this.props;
    if (defenders && defenders.items) {
      this.setState({
        defenderArray: defenders,
      });
    }
  }

  handleToggle = (event, data, row) => {
    const { onChange } = this.props;
    const { checked } = data;
    const newId = row.value;
    let { defenderSelection } = this.state;
    let { defenderDeselection } = this.state;
    let { defenderArray } = this.state;
    const currentFilteredData = this.reactTable.getResolvedState().sortedData;

    if (checked === true) {
      defenderSelection.push(row.value);
      defenderArray.push({
        id: row.value,
        firstName: row.row.defenderName.split(', ')[1],
        lastName: row.row.defenderName.split(', ')[0],
        email: row.row.email,
        phoneNumber: row.row.phoneNumber,
        company: row.row.company,
        defense_role: row.row.defense_role,
      });

      let selectAllChecked = true;

      for (let data of currentFilteredData) {
        if (!defenderArray.find(defender => defender.id === data.checkbox)) {
          selectAllChecked = false;
          break;
        }
      }

      this.setState({
        defenderArray,
        defenderSelectAll: selectAllChecked,
        defenderSelection,
      });
    }
    if (checked === false) {
      defenderArray = defenderArray.filter(item => item.id !== newId);

      let selectAllChecked = true;

      for (let data of currentFilteredData) {
        if (!defenderArray.find(defender => defender.id === data.checkbox)) {
          selectAllChecked = false;
          break;
        }
      }

      defenderDeselection.push({
        id: row.value,
        firstName: row.row.defenderName.split(', ')[1],
        lastName: row.row.defenderName.split(', ')[0],
        email: row.row.email,
        phoneNumber: row.row.phoneNumber,
        company: row.row.company,
        defense_role: row.row.defense_role,
      });

      this.setState({
        defenderArray,
        defenderSelectAll: selectAllChecked,
        defenderSelection,
        defenderDeselection,
      });
    }
    onChange('defendersInput', defenderArray);
  };

  handleSelectAll = (event, data, row) => {
    let { defenderArray } = this.state;
    const { onChange } = this.props;
    const { checked } = data;

    const currentFilteredData = this.reactTable.getResolvedState().sortedData;

    this.setState({ defenderSelectAll: checked });

    if (currentFilteredData.length > 0 && checked) {
      currentFilteredData.forEach(row => {
        if (!defenderArray.find(defender => defender.id === row.checkbox)) {
          defenderArray.push({
            id: row.checkbox,
            firstName: row.defenderName.split(', ')[1],
            lastName: row.defenderName.split(', ')[0],
            email: row.email,
            phoneNumber: row.phoneNumber,
            company: row.company,
            defense_role: row.defense_role,
          });
        }
      });
    } else if (!checked) {
      currentFilteredData.forEach(row => {
        defenderArray = defenderArray.filter(item => item.id !== row.checkbox);
      });
    }

    this.setState({
      defenderArray,
    });
    onChange('defendersInput', defenderArray);
  };

  handleGetData = async () => {
    const {
      client: { query },
    } = this.props;
    const { jurisdiction } = this.state;
    this.setState({
      loading: true,
    });
    const response = await query({
      query: ListDefenders,
      name: 'listDefenders',
      variables: {
        jurisdiction,
      },
    });

    let items = []
    let paginationToken = null
    do {
      const response = await query({
        query: ListDefenders,
        name:'listDefenders',
        variables: {
          jurisdiction: jurisdiction,
          paginate:true,
          paginationToken: paginationToken
        }
      })
      console.log('response', response)
      paginationToken = response.data.listDefenders.paginationToken
      items.push(...response.data.listDefenders.items)
    } while (paginationToken != undefined)

    this.setState({
      defenderResponse: items,
      loading: false,
    });
  };

  show = size => async () => {
    const { defenders } = this.props;
    this.handleGetData();
    this.setState({
      defenderArray: defenders,
      size,
      open: true,
      defenderSelection: [],
      defenderDeselection: [],
    });
  };

  close = () => {
    const { defenders } = this.props;
    const { onChange } = this.props;
    const holderSelection = defenders.filter(
      item => this.state.defenderSelection.indexOf(item.id) === -1
    );
    const holderDeselection = this.state.defenderDeselection;
    const holder = holderSelection.concat(holderDeselection);
    this.setState({
      open: false,
      defenderArray: [],
      defenderSelectAll: false,
      defenderSelection: [],
      defenderDeselection: [],
    });
    onChange('defendersInput', holder);
  };

  addDefender = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      open,
      size,
      defenderResponse,
      loading,
      defenderSelectAll,
      defenderArray,
    } = this.state;
    const {
      defenders,
      data: { error },
    } = this.props;
    let sortedDefenders = defenderResponse || []
    sortedDefenders = sortedDefenders.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    })
    return (
      <>
        <Button
          compact
          onClick={this.show('large')}
          basic
          color="teal"
          size="small"
          style={{ marginTop: '15px' }}
        >
          <Icon name="plus" /> Add Defense
        </Button>
        <Modal
          size={size}
          centered={false}
          open={open}
          onClose={this.close}
          closeIcon
        >
          <Modal.Header>Defense</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ paddingBottom: '20px' }}>
              From the list, select the members of the defense team who are assigned to the case.
            </Modal.Description>
            {this.state.defenderArray.length !== 0 && (
              <Modal.Description style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                {this.state.defenderArray.length} selected.
              </Modal.Description>
            )}
            {error || !sortedDefenders ? (
              <Message negative>
                <p>
                  There was an error while getting the list of available defense
                  members. Please close this popup and try again.
                </p>
              </Message>
            ) : (
                <Dimmer.Dimmable active={loading}>
                  <ReactTable
                    ref={r => (this.reactTable = r)}
                    data={
                      sortedDefenders
                      // defenderResponse.items.filter(
                      //   i => i.enabled && i.status === 'CONFIRMED'
                      // )
                    }
                    filterable
                    loading={loading}
                    defaultPageSize={10}
                    showPageSizeOptions={false}
                    style={{
                      height: '65vh',
                    }}
                    noDataText="No Defense Attorney Data Available"
                    showPagination
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value
                    }
                    onFilteredChange={() => {
                      let selectAllChecked = true;
                      const currentFilteredData = this.reactTable.getResolvedState()
                        .sortedData;

                      for (let data of currentFilteredData) {
                        if (
                          !defenderArray.find(
                            defender => defender.id === data.checkbox
                          )
                        ) {
                          selectAllChecked = false;
                          break;
                        }
                      }

                      this.setState({
                        defenderSelectAll: selectAllChecked,
                      });
                    }}
                    columns={[
                      {
                        id: 'defenderName',
                        Header: 'Name',
                        accessor: d => `${d.lastName}, ${d.firstName}`,

                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['defenderName'],
                          }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Organization',
                        accessor: 'company',
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ['company'] }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                        Cell: row => <span>{row.value || '-'}</span>,
                      },
                      {
                        Header: 'Email',
                        accessor: 'email',
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ['email'] }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Phone',
                        accessor: 'phoneNumber',
                        Cell: ({ value }) => (value = formatPhoneNumber(value)),
                        filterMethod: (filter, row) =>
                          row[filter.id].startsWith(filter.value) ||
                          row[filter.id].substring(2).startsWith(filter.value),
                        filterable: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Role',
                        accessor: 'defense_role',
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ['defense_role'] }),
                        filterAll: true,
                        style: {
                          fontSize: '14px',
                          border: 'none',
                        },
                      },
                      {
                        Header: 'Add to Case',
                        accessor: 'id',
                        filterable: true,
                        sortable: false,
                        style: {
                          textAlign: 'center',
                        },
                        filterMethod: (filter, row) => {
                          return true;
                        },
                        Filter: ({ filter, onChange }) => (
                          <Checkbox
                            label="Select All"
                            type="checkbox"
                            checked={defenderSelectAll}
                            onClick={(event, data) =>
                              this.handleSelectAll(event, data)
                            }
                          />
                        ),
                        id: 'checkbox',
                        Cell: row => {
                          return (
                            <Checkbox
                              id={row.id}
                              type="checkbox"
                              checked={
                                defenders.find(data => data.id === row.value) ||
                                false
                              }
                              onClick={(event, data) =>
                                this.handleToggle(event, data, row)
                              }
                            />
                          );
                        },
                      },
                    ]}
                  />
                  <Dimmer active={loading} inverted>
                    <Loader />
                  </Dimmer>
                </Dimmer.Dimmable>
              )}
          </Modal.Content>
          <Modal.Actions>
            {/* <Button onClick={() => this.logIt()}>Log it</Button> */}
            <Button negative size="small" onClick={this.close}>
              Cancel
            </Button>
            <Button positive size="small" onClick={this.addDefender}>
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
SearchDefendersModal.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  defenders: PropTypes.array,
  client: PropTypes.array.isRequired,
};

SearchDefendersModal.defaultProps = {
  defenders: [],
  data: [],
};

export default withApollo(SearchDefendersModal);
