import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import { Button, Dimmer, Loader, Message } from 'semantic-ui-react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import 'react-table/react-table.css';
import '../../styles/ReactTableCustom.css';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';

import {
  ListDefenders,
  DisableDefender,
  OnDefenderSubscription
} from './DefenderApi';

class DefenderTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction: '',
      listDefenders: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const {
      data: { subscribeToMore }
    } = this.props;

    subscribeToMore(buildSubscription(OnDefenderSubscription, ListDefenders));
  }

  async componentDidMount() {
    const {
      data: { subscribeToMore }
    } = this.props;

    let itemss = await this.paginate()
    console.log("items", itemss)
    let list = []
    list.push(...(itemss || []))
    list = list.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    });
    this.setState({ 
      listDefenders: list,
      isLoading:false
    })
    
  }


  handleDelete = async id => {
    const { disableDefender } = this.props;

    try {
      await disableDefender({ id });
    } catch (error) {
      // TODO: handle error case
    }
  };

  handleClick = row => {
    navigate('/app/defender/view', {
      state: { defenderId: row.value, active: row.row.enabled }
    });
  };

  async paginate () {
    console.log("this.props.data", this.props.data)
    let items = []
    let paginationToken = null
    console.log('paginationToken', paginationToken)
    const { client: { query } } = this.props;
    do {
    const response = await query({
      query: ListDefenders,
      name:'listDefenders',
      variables: {
        jurisdiction: this.props.jurisdiction,
        paginate:true,
        paginationToken: paginationToken
      }
    })
    // console.log('response', response)
    paginationToken = response.data.listDefenders.paginationToken
    items.push(...response.data.listDefenders.items)
  } while (paginationToken != undefined)
  return items
  }


  render() {
    const {
      data: { error, loading }
    } = this.props;
    const { listDefenders, isLoading } = this.state

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {listDefenders && (
          <Dimmer.Dimmable dimmed={loading || isLoading}>
            <ReactTable
              data={listDefenders}
              filterable
              defaultPageSize={10}
              showPageSizeOptions={false}
              style={{
                height: '79vh'
              }}
              showPagination
              noDataText='No Defense Attorney Data Available'
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              defaultFiltered={[
                {
                  id: 'enabled',
                  filter: 'all'
                }
              ]}
              columns={[
                {
                  id: 'defenderName',
                  Header: 'Name',
                  accessor: d => `${d.lastName}, ${d.firstName}`,

                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defenderName']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none'
                  }
                },
                {
                  Header: 'Organization',
                  accessor: 'company',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['company']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none'
                  },
                  Cell: row => <span>{row.value || '-'}</span>
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['email']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                    wordWrap: 'break-word'
                  }
                },
                {
                  Header: 'Phone',
                  accessor: 'phoneNumber',
                  Cell: ({ value }) => (value = formatPhoneNumber(value)),
                  filterMethod: (filter, row) =>
                    row[filter.id].includes(filter.value),
                  filterable: true,
                  style: {
                    fontSize: '14px',
                    border: 'none',
                    wordWrap: 'break-word'
                  }
                },
                {
                  Header: 'Role',
                  accessor: 'defense_role',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defense_role']
                    }),
                  filterAll: true,
                  style: {
                    fontSize: '14px',
                    border: 'none'
                  },
                  Cell: row => <span>{row.value || '-'}</span>
                },
                {
                  Header: 'Account Status',
                  accessor: 'enabled',
                  Cell: ({ value, original }) => (
                    <>
                      <div>
                        {value === true
                          ? original.status === 'FORCE_CHANGE_PASSWORD'
                            ? 'Registered, Not Confirmed'
                            : 'Active'
                          : 'Disabled'}
                      </div>
                    </>
                  ),
                  filterable: true,
                  sortable: false,
                  style: {
                    fontSize: '14px',
                    border: 'none'
                  },
                  // NOTE: Check README.md for the possible scenarios
                  filterMethod: (filter, row) => {
                    if (filter.value === 'not_confirmed') {
                      console.log('Filter method', filter, row);
                      return (
                        row[filter.id] === true &&
                        row._original.status === 'FORCE_CHANGE_PASSWORD'
                      );
                    }
                    if (filter.value === 'true') {
                      return (
                        row[filter.id] === true &&
                        row._original.status !== 'FORCE_CHANGE_PASSWORD'
                      );
                    }
                    if (filter.value === 'false') {
                      return row[filter.id] === false;
                    }
                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'all'}
                      style={{
                        height: '28px',
                        width: '100%'
                      }}
                    >
                      <option value='all'>All</option>
                      <option value='true'>Active</option>
                      <option value='false'>Disabled</option>
                      <option value='not_confirmed'>
                        Registered, Not Confirmed
                      </option>
                    </select>
                  )
                },
                {
                  Header: '',
                  accessor: 'id',
                  filterable: true,
                  sortable: false,
                  style: {
                    textAlign: 'center'
                  },
                  Cell: row => (
                    <Button
                      onClick={() => this.handleClick(row)}
                      color='teal'
                      size='small'
                      compact
                    >
                      View Profile
                    </Button>
                  ),
                  filterMethod: (filter, row) => {
                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.38)'
                      }}
                    >
                      <i aria-hidden='true' class='search icon' /> Column
                      Filters
                    </span>
                  )
                }
              ]}
            />
            <Dimmer active={loading || isLoading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

DefenderTable.propTypes = {
  data: PropTypes.object,
  disableDefender: PropTypes.func.isRequired
};

DefenderTable.defaultProps = {
  data: {}
};

export default flowRight(
  graphql(ListDefenders, {
    options: props => {
      return {
        variables: {
          jurisdiction: props.jurisdiction,
          paginate:true
        }
      };
    }
  }),
  graphqlMutation(DisableDefender, ListDefenders)
)(DefenderTable);
